import Vue from 'vue'
import App from './App.vue'

import Axios from 'axios';
import FLoading from 'vue-loading-overlay';
import Notifications from 'vue-notification';
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VeeValidate from 'vee-validate';
//import VueHtmlToPaper from "vue-html-to-paper";
import vuetify from './plugins/vuetify'

import NotifyDlgPlugin from './plugins/notificationDlg/NotifyDlgPlugin';

import './assets/my.css';
import Filters from './filters';
import router from './router';
import store from './store/store';

Vue.config.productionTip = false

Vue.use(Filters);
Vue.use(FLoading);
Vue.use(Notifications);
Vue.use(NotifyDlgPlugin);
Vue.use(PerfectScrollbar);
Vue.use(VeeValidate);

Axios.defaults.baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://bacben.bangaloreallergycentre.com/api'
    : 'http://localhost:54320/api';

// const pOptions = {
//   name: "_blank",
//   specs: ["fullscreen=false", "titlebar=false", "scrollbars=false"],
//   styles: ["/ph.css", "/vuetify_latest.css"]
// };
// Vue.use(VueHtmlToPaper, pOptions);

Vue.prototype.$eventBus = new Vue();

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
