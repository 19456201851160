const genderConverter = {
  /**
   * @return {string}
   */
  IntToString(value) {
    return value === 0 ? "Male" : value === 1 ? "Female" : "Others";
  },
  /**
   * @return {number}
   */
  StringToInt(value) {
    return value === "Male" ? 0 : value === "Female" ? 1 : 2;
  }
};
export default genderConverter;
// export default function GenderIntToStringConverter(value) {
//   return value === 0 ? "Male" : value === 1 ? "Female" : "Others";
// }
