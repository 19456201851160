import WebH from "./web";

const drugCatsApi = {
  async get() {
    const resp = await WebH.Get("/drugcats", "");
    if (resp.Success) {
      return resp.Data;
    }
    return null;
  },

  async add(data) {
    return await WebH.Post("/drugcats", data);
  },

  async update(data) {
    return await WebH.Put("/drugcats/" + data.Id, data);
  },
  async delete(id) {
    return await WebH.Delete("/drugcats/" + id);
  }
};

export default drugCatsApi;
