<template>
  <div id='appRoot'>
    <template v-if='!$route.meta.public'>
      <v-app id='inspire' app>
        <AppDrawer class="app-drawer"></AppDrawer>
        <AppToolbar class="app-toolbar"></AppToolbar>
        <notifications position='bottom right'></notifications>
        <v-main>
          <v-container fluid>
            <div class='page-wrapper'>
              <router-view></router-view>
            </div>
          </v-container>
        </v-main>
        <v-footer app height='auto' class='grey pa-3 app--footer'>
          <span class='caption'
          >Designed by Ajit Hegde &copy; {{ new Date().getFullYear() }}</span
          >
        </v-footer>
        <AppFab></AppFab>
      </v-app>
    </template>
    <template v-else>
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </template>
    <v-snackbar
      :timeout='3000'
      bottom
      right
      :color='snackbar.color'
      v-model='snackbar.show'
    >
      {{ snackbar.text }}
      <v-btn dark text @click.native='snackbar.show = false' icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import AppDrawer from './components/layout/AppDrawer.vue';
import AppFab from './components/layout/AppFab.vue';
import AppToolbar from './components/layout/AppToolbar.vue';
export default {
  name: 'App',

  components: {
    AppDrawer,
    AppFab,
    AppToolbar
  },

  data: () => ({
    expanded: true,
    rightDrawer: false,
    snackbar: {
      show: false,
      text: '',
      color: ''
    }
  }),

  beforeCreate() {
    this.$store
      .dispatch('UserStore/autoSignIn')
      .then(() => this.$router.replace('/'));
  },

  created() {
    this.$vuetify.theme.themes.light.primary = '#1b5e20';
    this.$vuetify.theme.themes.light.secondary = '#424242';
    this.$vuetify.theme.themes.light.accent = '#82B1FF';
    this.$vuetify.theme.themes.light.error = '#FF5252';
    this.$vuetify.theme.themes.light.info = '#2196F3';
    this.$vuetify.theme.themes.light.warning = '#FFC107';
    this.$vuetify.theme.themes.light.success = '#4CAF50';
    this.loadSettings();
  },

  methods: {
    async loadSettings() {
      const permissions = this.$store.getters['UserStore/permissions'];
      if (!permissions || permissions.length < 1) return;
      await this.$store.dispatch('SettingsStore/loadSettings');
      if (permissions.includes('dr.v')) {
        await this.$store.dispatch('DrugsStore/loadDrugs');
      }
    }
  }

};
</script>
