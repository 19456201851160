<template>
  <v-navigation-drawer
    id='appDrawer'
    :mini-variant.sync='mini'
    fixed
    light
    app
    v-model='drawer'
    width='260'
  >
    <v-app-bar>
      <v-app-bar-title>
        <img class='resize' src='../../../public/img/bac_logo.svg' alt=''/>
      </v-app-bar-title>
    </v-app-bar>
    <perfect-scrollbar>
      <v-list dense expand>
        <template v-for='(item, i) in menus'>
          <v-list-group
            v-if='item.items && checkPermission(item)'
            :key='item.name'
            :group='item.group'
            :prepend-icon='item.icon'
            no-action='no-action'
          >
            <v-list-item
              slot='activator'
              ripple='ripple'
              v-if='checkPermission(item)'
            >
              <v-list-item-content>
                <v-list-item-title class='item-title'>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-for='(subItem, i) in item.items'>
              <v-list-group
                v-if='subItem.items'
                :key='subItem.name'
                :group='subItem.group'
                sub-group='sub-group'
              >
                <v-list-item
                  slot='activator'
                  ripple='ripple'
                  v-if='checkPermission(subItem)'
                  color='primary'
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-for='(grand, i) in subItem.children'
                  :key='i'
                  :to='genChildTarget(item, grand)'
                  :href='grand.href'
                  ripple='ripple'
                  color='primary'
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ grand.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-list-item
                v-else-if='checkPermission(subItem)'
                :key='i'
                :to='genChildTarget(item, subItem)'
                :href='subItem.href'
                :disabled='subItem.disabled'
                :target='subItem.target'
                ripple='ripple'
                color='primary'
              >
                <v-list-item-content>
                  <v-list-item-title
                  ><span>{{ subItem.title }}</span></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-icon v-if='subItem.action'>
                  <v-icon :class='[subItem.actionClass || "success--text"]'>{{
                      subItem.action
                    }}
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-list-group>

          <v-subheader v-else-if='item.header' :key='i'>{{
              item.header
            }}
          </v-subheader>
          <v-divider v-else-if='item.divider' :key='i'></v-divider>

          <v-list-item
            v-else-if='checkPermission(item)'
            :to='!item.href ? { name: item.name } : null'
            :href='item.href'
            ripple='ripple'
            :disabled='item.disabled'
            :target='item.target'
            rel='noopener'
            :key='item.name'
            color='primary'
          >
            <v-list-item-icon v-if='item.icon'>
              <v-icon v-text='item.icon'></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text='item.title'></v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon v-if='item.subAction'>
              <v-icon v-text='item.subAction'></v-icon>
            </v-list-item-icon>

          </v-list-item>
        </template>
      </v-list>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import menu from '../../api/menu';

export default {
  props: {
    expanded: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    mini: false,
    drawer: true,
    menus: menu,
    scrollSettings: {
      maxScrollbarLength: 160
    }
  }),

  created() {
    this.$eventBus.$on('APP_DRAWER_TOGGLED', this.toggleDrawer);
  },
  beforeDestroy() {
    this.$eventBus.$off('APP_DRAWER_TOGGLED');
  },

  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    genChildTarget(item, subItem) {
      if (subItem.href) return;
      if (subItem.alias) {
        return {
          name: subItem.alias
        };
      }
      return { name: `${item.group}/${subItem.name}` };
    },
    checkPermission(item) {
      if (!item.permission) {
        return true;
      } else {
        const permissions = this.$store.getters['UserStore/permissions'];
        if (!permissions) return false;
        return !!permissions.includes(item.permission);
      }
    }
  }
};
</script>

<style scoped>

.v-list-item__title {
  font-weight: 400 !important;
}

.v-list-item__content {
  justify-content: start;
  text-align: left;
  display: grid;
}

img.resize {
  max-width: 50%;
  max-height: 50%;
}
</style>
