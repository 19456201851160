import Moment from "moment";

// Vue.filter('formatDateTime',value=>{
//   if(value)
//     return Moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
// });
//
// Vue.filter('formatDate',value=>{
//   if(value)
//     return Moment(String(value)).format('DD-MM-YYYY');
// });

const dateFormats = {
  /**
   * @return {string}
   */
  FormatDateTime(value) {
    if (value) return Moment(String(value)).format("DD-MM-YYYY HH:mm:ss");
  },
  /**
   * @return {string}
   */
  FormatDateIST(value) {
    if (value) return Moment(String(value)).format("DD-MM-YYYY");
  },
  /**
   * @return {string}
   */
  FormatDateISO(value) {
    if (value) return Moment(String(value)).format("YYYY-MM-DD");
  },
  /**
   * @return {string}
   */
  FormatDateTimeISO(value) {
    if (value) return Moment(String(value)).toISOString();
  }
};

export default dateFormats;
