const state = {
  search: {
    pid: '',
    mob: '',
    from: '',
    to: '',
    st: 0
    //pn: 0
  },
  loading: false,
  total: 0,
  appointments: []
};
const mutations = {
  setPid(state, data) {
    state.search.pid = data;
  },
  setMob(state, data) {
    state.search.mob = data;
  },
  setFrom(state, data) {
    state.search.from = data;
  },
  setTo(state, data) {
    state.search.to = data;
  },
  setSt(state, data) {
    state.search.st = data;
  },
  setLoading(state, data) {
    state.loading = data;
  },
  setTotal(state, data) {
    state.total = data;
  },
  setAppointments(state, data) {
    state.appointments = data;
  }
};
const getters = {
  Search: state => {
    return state.search;
  },
  Loading: state => {
    return state.loading;
  },
  Total: state => {
    return state.total;
  },
  Appointments: state => {
    return state.appointments;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
  //actions
};
