import NotifyDlg from "./NotifyDlg.vue";

export default {
  install: Vue => {
    Vue.prototype.$notifyDlg = {
      show(message, title, messageType, confirmDlg) {
        return new Promise(resolve => {
          const props = {
            message,
            title,
            messageType,
            confirmDlg
          };
          const cmp = new Vue(
            Object.assign(NotifyDlg, {
              destroyed: () => {
                document.body.removeChild(cmp.$el);
                resolve(cmp.value);
              }
            })
          );
          Object.assign(cmp, props);
          document.body.appendChild(cmp.$mount().$el);
        });
      }
    };
  }
};
