import Vue from "vue";
import Router from "vue-router";
import paths from "./path";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import UserStore from "../store/modules/users/User";

Vue.use(Router);

const router = new Router({
  base: "/",
  mode: "history",
  linkActiveClass: "active",
  routes: paths
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const permissions = UserStore.state.permissions;
    if (!permissions) next("/book-appointment");
    else if (permissions.length < 1) {
      next("/book-appointment");
    } else if (to.meta.requirePermission) {
      if (permissions.includes(to.meta.requirePermission)) {
        next();
      } else {
        next("/403");
      }
    } else {
      next();
    }
  } else next();
});

router.afterEach(() => {
  // ...
  NProgress.done();
});

export default router;
