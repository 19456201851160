const state = {
  isReg: 'no',
  patient: {
    Pid: '',
    Name: '',
    Mobile: ''
  },
  regDate: null,
  regSlot: -1
};

const mutations = {
  updateIsReg(state, data) {
    state.isReg = data;
  },
  setPatientId(state, pid) {
    state.patient.Pid = pid;
  },
  setPatientName(state, data) {
    state.patient.Name = data;
  },
  setPatientMobile(state, data) {
    state.patient.Mobile = data;
  },
  setRegDate(state, data) {
    state.regDate = data;
  },
  setRegSlot(state, data) {
    state.regSlot = data;
  },
  reset(state) {
    state.patient = {
      Pid: '',
      Name: '',
      Mobile: ''
    };
    state.regDate = null;
    state.regSlot = -1;
    state.isReg = 'no';
  }
};

const getters = {
  patient: state => {
    return state.patient;
  },
  isReg: state => {
    return state.isReg;
  },
  regDate: state => {
    return state.regDate;
  },
  regSlot: state => {
    return state.regSlot;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
  //actions
};
