import Vue from 'vue';
import Vuex from 'vuex';

import UserStore from './modules/users/User';
import SettingsStore from './modules/settings/Settings';
import ApptStore from './modules/appointment/Appointment';
import FormsStore from './modules/forms/Forms';
import HabitsStore from './modules/forms/Habits';
import ApptsStore from './modules/appointment/Appts';
import DrugsStore from './modules/drugs/Drugs';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    UserStore,
    SettingsStore,
    ApptStore,
    FormsStore,
    HabitsStore,
    ApptsStore,
    DrugsStore
  },
  state: {},
  mutations: {},
  actions: {}
});
