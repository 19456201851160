import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css';

Vue.use(Vuetify, {
  theme: {
    themes : {
      light: {
        primary: "#1b5e20",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        warning: "#FFC107",
        success: "#4CAF50"
      },
      dark: {
        primary: "#1b5e20",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        warning: "#FFC107",
        success: "#4CAF50"
      }
    }
  },
  options: {
    customProperties: true,
  }
});

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  }
});
