/**
 * @return {string}
 */
export default function IntToApptStatusConverter(value) {
  switch (value) {
    case 0:
      return "On Going";
    case 1:
      return "Completed";
    case 2:
      return "Cancelled";
  }
  return "";
}
