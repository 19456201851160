const state = {
  patient: {
    Pid: '',
    Mobile: ''
  },
  problems: {},
  treatments: {},
  family: {},
  habits: {},
  others: {},
  children: {}
};

const mutations = {
  setPatientId(state, pid) {
    state.patient.Pid = pid;
  },
  setPatientMobile(state, data) {
    state.patient.Mobile = data;
  },
  SetProblems(state, data) {
    state.problems = data;
  },
  SetTreatments(state, data) {
    state.treatments = data;
  },
  SetFamily(state, data) {
    state.family = data;
  },
  SetHabits(state, data) {
    state.habits = data;
  },
  SetOthers(state, data) {
    state.others = data;
  },
  SetChildren(state, data) {
    state.children = data;
  },
  reset(state) {
    state.patient = {
      Pid: '',
      Mobile: ''
    };
    state.problems = {};
    state.treatments = {};
    state.family = {};
    state.habits = {};
    state.others = {};
    state.children = {};
  }
};

const getters = {
  patient: state => {
    return state.patient;
  },
  Problems: state => {
    return state.problems;
  },
  Treatments: state => {
    return state.treatments;
  },
  Family: state => {
    return state.family;
  },
  Habits: state => {
    return state.habits;
  },
  Others: state => {
    return state.others;
  },
  Children: state => {
    return state.children;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
