<template>
  <v-dialog value="true" max-width="500px" @input="change">
    <v-card>
      <v-card-title>
        <!--<v-icon :color="getBtnColor()" small>{{ getIcon() }}</v-icon-->
        <!--&gt;&nbsp;&nbsp;-->
        <div class="headline">{{ title }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="getBtnColor()" v-if="confirmDlg" @click="choose(true)"
          >Yes</v-btn
        >
        <v-btn :color="confirmDlg ? '' : 'primary'" @click="choose(false)">{{
          getBtnText()
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    confirmDlg: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    messageType: {
      type: String,
      default: "warning"
    }
  },
  data() {
    return {
      value: false
    };
  },
  methods: {
    choose(value) {
      this.$emit("result", value);
      this.value = value;
      this.$destroy();
    },
    change() {
      this.$destroy();
    },
    getBtnText() {
      if (this.confirmDlg) return "No";
      return "Ok";
    },
    getBtnColor() {
      if (this.messageType === "warning") return "red";
      else if (this.messageType === "success") return "green";
      else return "blue";
    },
    getIcon() {
      if (this.messageType === "warning") return "fa fa-exclamation-triangle";
      else if (this.messageType === "error") return "fa fa-exclamation-circle";
      else if (this.messageType === "info") return "fa fa-info";
    }
  }
};
</script>
