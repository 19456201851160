/**
 * @return {string}
 */
export default function IntToSlotConverter(value) {
  switch (value) {
    case 1:
      return "9:00 AM - 9:15 AM";
    case 2:
      return "9:16 AM - 9:26 AM";
    case 3:
      return "9:27 AM - 9:37 AM";
    case 4:
      return "9:38 AM - 9:48 AM";
    case 5:
      return "9:49 AM - 9:59 AM";
    case 6:
      return "10:00 AM - 10:15 AM";
    case 7:
      return "10:16 AM - 10:26 AM";
    case 8:
      return "10:27 AM - 10:37 AM";
    case 9:
      return "10:38 AM - 10:48 AM";
    case 10:
      return "10:49 AM - 10:59 AM";
    case 11:
      return "11:00 AM - 11:15 AM";
    case 12:
      return "11:16 AM - 11:26 AM";
    case 13:
      return "11:27 AM - 11:37 AM";
    case 14:
      return "11:38 AM - 11:48 AM";
    case 15:
      return "11:49 AM - 11:59 AM";
    case 16:
      return "12:00 PM - 12:15 PM";
    case 17:
      return "12:16 PM - 12:26 PM";
    case 18:
      return "12:27 PM - 12:37 PM";
    case 19:
      return "12:38 PM - 12:48 PM";
    case 20:
      return "12:49 PM - 12:59 PM";
    case 21:
      return "1:00 PM - 1:15 PM";
    case 22:
      return "1:16 PM - 1:26 PM";
    case 23:
      return "1:27 PM - 1:37 PM";
    case 24:
      return "1:38 PM - 1:48 PM";
    case 25:
      return "1:49 PM - 1:59 PM";
    //case 26: return "2:00 PM - 2:15 PM";
    //case 27: return "2:16 PM - 2:26 PM";
    //case 28: return "2:27 PM - 2:37 PM";
    //case 29: return "2:38 PM - 2:48 PM";
    //case 30: return "2:49 PM - 2:59 PM";
    case 31:
      return "3:00 PM - 3:15 PM";
    case 32:
      return "3:16 PM - 3:26 PM";
    case 33:
      return "3:27 PM - 3:37 PM";
    case 34:
      return "3:38 PM - 3:48 PM";
    case 35:
      return "3:49 PM - 3:59 PM";
    case 36:
      return "4:00 PM - 4:15 PM";
    case 37:
      return "4:16 PM - 4:26 PM";
    case 38:
      return "4:27 PM - 4:37 PM";
    case 39:
      return "4:38 PM - 4:48 PM";
    case 40:
      return "4:49 PM - 4:59 PM";
    case 41:
      return "5:00 PM - 5:15 PM";
    case 42:
      return "5:16 PM - 5:26 PM";
    case 43:
      return "5:27 PM - 5:37 PM";
    case 44:
      return "5:38 PM - 5:48 PM";
    case 45:
      return "5:49 PM - 5:59 PM";
    case 46:
      return "6:00 PM - 6:15 PM";
    case 47:
      return "6:16 PM - 6:26 PM";
    case 48:
      return "6:27 PM - 6:37 PM";
    case 49:
      return "6:38 PM - 6:48 PM";
    case 50:
      return "6:49 PM - 6:59 PM";
  }
  return "";
}
