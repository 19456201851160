const state = {
  Smoking: null,
  AlcoholDrinking: null,
  Travelling: null,
  Pets: '',
  Diet: '',
  FoodComplains: { Choices: [], Others: '' },
  Treatments: { Diabetes: '', HyperTension: '', Others: '' },
  Family: { Choices: [], Others: '' }
};

const mutations = {
  setSmoking(state, data) {
    state.Smoking = data;
  },
  setAlcoholDrinking(state, data) {
    state.AlcoholDrinking = data;
  },
  setTravelling(state, data) {
    state.Travelling = data;
  },
  setPets(state, data) {
    state.Pets = data;
  },
  setDiet(state, data) {
    state.Diet = data;
  },
  setFoodComplainsChoices(state, data) {
    state.FoodComplains.Choices = data;
  },
  setFoodComplainsOthers(state, data) {
    state.FoodComplains.Others = data;
  },
  setTreatmentsDiabetes(state, data) {
    state.Treatments.Diabetes = data;
  },
  setTreatmentsHyperTension(state, data) {
    state.Treatments.HyperTension = data;
  },
  setTreatmentsOthers(state, data) {
    state.Treatments.Others = data;
  },
  setFamilyChoices(state, data) {
    state.Family.Choices = data;
  },
  setFamilyOthers(state, data) {
    state.Family.Others = data;
  },
  reset(state) {
    state.Smoking = null;
    state.AlcoholDrinking = null;
    state.Travelling = null;
    state.Pets = '';
    state.Diet = '';
    state.FoodComplains = { Choices: [], Others: '' };
    state.Treatments = { Diabetes: '', HyperTension: '', Others: '' };
    state.Family = { Choices: [], Others: '' };
  }
};

const getters = {
  Smoking: state => {
    return state.Smoking;
  },
  AlcoholDrinking: state => {
    return state.AlcoholDrinking;
  },
  Travelling: state => {
    return state.Travelling;
  },
  Pets: state => {
    return state.Pets;
  },
  Diet: state => {
    return state.Diet;
  },
  FoodComplains: state => {
    return state.FoodComplains;
  },
  Treatments: state => {
    return state.Treatments;
  },
  Family: state => {
    return state.Family;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
