export default [
  {
    path: "*",
    meta: {
      public: true
    },
    redirect: {
      path: "/404"
    }
  },
  {
    path: "/404",
    meta: {
      public: true
    },
    name: "NotFound",
    component: () => import("../pages/general/NotFound.vue")
  },
  {
    path: "/403",
    meta: {
      public: true
    },
    name: "AccessDenied",
    component: () => import("../pages/general/Deny.vue")
  },
  {
    path: "/book-appointment",
    meta: {
      public: true
    },
    component: () => import("../pages/general/Appt1.vue"),
    children: [
      {
        path: "",
        meta: {
          public: true
        },
        name: "book_appointment",
        component: () => import("../pages/general/CreateAppointment")
      },
      {
        path: "/allergy-questions",
        meta: {
          public: true
        },
        name: "allergy_quests",
        component: () => import("../pages/general/AllergyQuests")
      },
      {
        path: "/more-questions",
        meta: {
          public: true
        },
        name: "more_questions",
        component: () => import("../pages/general/MoreQuestions")
      }
      // {
      //   path: "/habits-form",
      //   meta: {
      //     public: true
      //   },
      //   name: "habits_form",
      //   component: () => import("../pages/general/HabitsForm")
      // }
    ]
  },
  {
    path: "/",
    name: "Root",
    meta: {},
    redirect: {
      name: "Dashboard"
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      requiresAuth: true
    },
    component: () => import("../pages/Dashboard.vue")
  },
  {
    path: "/old-data",
    name: "old-data",
    meta: {
      requiresAuth: true
    },
    component: () => import("../pages/patients/OldPatient")
  },
  {
    path: "/appointments",
    component: () => import("../components/layout/Base"),
    children: [
      {
        path: "",
        name: "appointments",
        meta: {
          requiresAuth: true
        },
        component: () => import("../pages/appointment/Appointments")
      },
      {
        path: "/appointment",
        name: "appointment",
        props: true,
        meta: {
          requiresAuth: true
        },
        component: () => import("../pages/appointment/Appointment")
      }
    ]
  },
  {
    path: "/patients",
    name: "patients",
    meta: {
      requiresAuth: true,
      requiresPermit: "set.v"
    },
    component: () => import("../pages/patients/Patients")
  },
  {
    path: "/patient_questionnaire",
    name: "patient_questionnaire",
    meta: {
      requiresAuth: true,
      requiresPermit: "pat.v"
    },
    component: () => import("../pages/patient_questionnaire/PatientQuestionnaire.vue")
  },
  {
    path: "/allergy_survey_sheet",
    name: "forms/allergy_survey_sheet",
    meta: {
      requiresAuth: true,
      requiresPermit: "pat.v"
    },
    component: () => import("../pages/forms/AllergySurveySheet")
  },
  {
    path: "/examinations",
    name: "exams",
    meta: {
      requiresAuth: true,
      requiresPermit: "pat.v"
    },
    component: () => import("../pages/Exams/Exams")
  },
  {
    path: "/investigations",
    name: "investigations",
    meta: {
      requiresAuth: true,
      requiresPermit: "pat.v"
    },
    component: () => import("../pages/investigations/Investigations")
  },
  {
    path: "/diagnoses",
    name: "diagnoses",
    meta: {
      requiresAuth: true,
      requiresPermit: "pat.v"
    },
    component: () => import("../pages/diagnosis/Diagnoses")
  },
  {
    path: "/spt",
    name: "spt",
    meta: {
      requiresAuth: true,
      requiresPermit: "s.v"
    },
    component: () => import("../pages/spt/Spts")
  },
  {
    path: "/ige",
    name: "ige",
    meta: {
      requiresAuth: true,
      requiresPermit: "l.v"
    },
    component: () => import("../pages/lab/LabReports")
  },
  {
    path: "/drug-prescriptions",
    name: "med/drugPrescriptions",
    meta: {
      requiresAuth: true,
      requiresPermit: "dr.v"
    },
    component: () => import("../pages/drugs/DrugPrescriptions")
  },
  {
    path: "/drugs",
    name: "med/drugs",
    meta: {
      requiresAuth: true,
      requiresPermit: "dr.v"
    },
    component: () => import("../pages/drugs/Drugs")
  },
  {
    path: "/drug-categories",
    name: "med/cats",
    meta: {
      requiresAuth: true,
      requiresPermit: "dr.v"
    },
    component: () => import("../pages/drugs/DrugCats")
  },
  {
    path: "/prescription",
    name: "prescription",
    meta: {
      requiresAuth: true,
      requiresPermit: "pr.v"
    },
    component: () => import("../pages/prescription/Prescription")
  },
  {
    path: "/case_sheet",
    name: "case_sheet",
    meta: {
      requiresAuth: true,
      requiresPermit: "pr.v"
    },
    component: () => import("../pages/prescription/Case_Sheet")
  },
  {
    path: "/sms",
    name: "sms",
    component: () => import("../pages/Sms/Sms"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/questions",
    name: "questions",
    component: () => import("../components/layout/Base"),
    children: [
      {
        path: "items",
        name: "questions/quest",
        meta: {
          requiresAuth: true
        },
        component: () => import("../pages/question/Questions")
      },
      {
        path: "groups",
        name: "questions/groups",
        meta: {
          requiresAuth: true
        },
        component: () => import("../pages/question/QuestionGroups")
      }
    ]
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      requiresAuth: true,
      requiresPermit: "set.v"
    },
    component: () => import("../pages/settings/Settings")
  },
  {
    path: "/accounts",
    name: "accounts",
    component: () => import("../components/layout/Base"),
    children: [
      {
        path: "roles",
        name: "accounts/roles",
        meta: {
          requiresAuth: true,
          requiresPermit: "usr.v"
        },
        component: () => import("../pages/account/Roles")
      },
      {
        path: "users",
        name: "accounts/users",
        meta: {
          requiresAuth: true,
          requiresPermit: "usr.v"
        },
        component: () => import("../pages/account/Users")
      }
    ]
  }
];
