import WebH from "./web";

const drugsApi = {
  async get() {
    const resp = await WebH.Get("/drugs", "Medicines");
    if (resp.Success) {
      return resp.Data;
    }
    return null;
  },

  async add(data) {
    return await WebH.Post("/drugs", data);
  },

  async update(data) {
    return await WebH.Put("/drugs/" + data.Id, data);
  },
  async delete(id) {
    return await WebH.Delete("/drugs/" + id);
  }
};

export default drugsApi;
