import Axios from "axios";
import Vue from "vue";
const WebH = {
  getErrorMessage(error) {
    let message = "";
    if (error.response && error.response.data) {
      message = error.response.data;
    } else {
      message = error.message;
    }
    return message;
  },
  Success(data) {
    return {
      Success: true,
      Data: data
    };
  },
  Fail(data) {
    return {
      Success: false,
      Data: data
    };
  },
  async Post(url, data) {
    try {
      const resp = await Axios.post(url, data);
      //console.log(resp);
      return this.Success(resp.data);
    } catch (error) {
      return this.Fail(this.getErrorMessage(error));
    }
  },
  async Put(url, data) {
    try {
      const resp = await Axios.put(url, data);
      //console.log(resp);
      return this.Success(resp.data);
    } catch (error) {
      return this.Fail(this.getErrorMessage(error));
    }
  },
  async Delete(url) {
    try {
      await Axios.delete(url);
      return this.Success("");
    } catch (error) {
      return this.Fail(this.getErrorMessage(error));
    }
  },
  async Get1(url) {
    try {
      const resp = await Axios.get(url);
      return this.Success(resp.data);
    } catch (error) {
      return this.Fail(this.getErrorMessage(error));
    }
  },
  async Get(url, message = "") {
    try {
      const resp = await Axios.get(url);
      // Vue.notify({ type: "info", text: message + " retrieved successfully" });
      return this.Success(resp.data);
    } catch (error) {
      const em =
        "Error when retrieving " + message + "\n" + this.getErrorMessage(error);
      Vue.notify({ type: "error", text: em });
      return this.Fail();
    }
  }
};

export default WebH;
