<template>
  <v-app-bar fixed app>
    <v-app-bar-title class='ml-0 pl-3'>
      <v-app-bar-nav-icon
        @click.stop='handleDrawerToggle'
      ></v-app-bar-nav-icon>
    </v-app-bar-title>
    <img class='resize' src='../../../public/img/bac_logo.svg' alt='BAC' />
    &nbsp;&nbsp;&nbsp;
    <div class='display-1 font-weight-medium green--text text--darken-4'>
      Bengaluru
    </div>
    <v-spacer></v-spacer>
    <v-menu
      offset-y
      origin='center center'
      :nudge-bottom='10'
      transition='scale-transition'
    >
      <template v-slot:activator='{ on }'>
        <v-btn icon large text v-on="on">
          <v-avatar size='30px'>
            <v-icon>fa fa-user</v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <v-list class='pa-0'>
        <v-list-item
          v-for='(item, index) in items'
          :to='!item.href ? { name: item.name } : null'
          :href='item.href'
          @click='item.click'
          ripple='ripple'
          :disabled='item.disabled'
          :target='item.target'
          rel='noopener'
          :key='index'
        >
          <v-list-item-action v-if='item.icon'>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <div>
      <v-dialog v-model='dlgShow' scrollable persistent max-width='400px'>
        <v-card>
          <v-card-title>
            <div class='title font-weight-medium'>
              Change password
            </div>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model.trim='dlgModel.OldPwd'
              :type='pwd1 ? "text" : "password"'
              :append-icon='pwd1 ? "fa fa-eye" : "fa fa-eye-slash"'
              @click:append='pwd1 = !pwd1'
              name='oldPwd'
              label='Old Password:'
              v-validate='{ required: true }'
              :error='!!errors.has("oldPwd")'
              :error-messages='errors.first("oldPwd")'
            ></v-text-field>
            <v-text-field
              :type='pwd2 ? "text" : "password"'
              :append-icon='pwd2 ? "fa fa-eye" : "fa fa-eye-slash"'
              @click:append='pwd2 = !pwd2'
              v-model.trim='dlgModel.NewPwd'
              name='newPwd'
              label='New Password:'
              v-validate='{ required: true }'
              :error='!!errors.has("newPwd")'
              :error-messages='errors.first("newPwd")'
            ></v-text-field>
            <v-text-field
              :type='pwd3 ? "text" : "password"'
              :append-icon='pwd3 ? "fa fa-eye" : "fa fa-eye-slash"'
              @click:append='pwd3 = !pwd3'
              v-model.trim='dlgModel.ConPwd'
              name='conPwd'
              label='Confirm Password:'
              v-validate='{ required: true }'
              :error='!!errors.has("conPwd")'
              :error-messages='errors.first("conPwd")'
            ></v-text-field>
            <div v-show='submit.error'>
              <v-list two-line>
                <v-list-item>
                  <span class='red--text'>{{ submit.message }}</span>
                </v-list-item>
              </v-list>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat :disabled='submit.working' @click.native='clearDlg'
              >Close</v-btn
            >
            <v-btn
              color='blue darken-1'
              flat
              :loading='submit.working'
              @click.native='saveDlg'
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app-bar>
</template>

<script>
import WebH from '../../api/web';
//import Util from '../../util';
export default {
  data() {
    return {
      items: [
        {
          icon: 'fa fa-key',
          href: '#',
          title: 'Change password',
          click: this.showChangePasswordDlg
        },
        {
          icon: 'fa fa-sign-out-alt',
          href: '#',
          title: 'Logout',
          click: this.logout
        }
      ],
      pwd1: false,
      pwd2: false,
      pwd3: false,
      dlgShow: false,
      dlgModel: {
        Email: '',
        OldPwd: '',
        NewPwd: '',
        ConPwd: ''
      },
      submit: {
        error: false,
        working: false,
        message: ''
      }
    };
  },
  // computed: {
  //   user() {
  //     return this.$store.getters['UserStore/user'];
  //   }
  //   // toolbarColor() {
  //   //   return this.$vuetify.options.extra.mainNav;
  //   // }
  // },
  methods: {
    handleDrawerToggle() {
      this.$eventBus.$emit('APP_DRAWER_TOGGLED');
    },
    // handleFullScreen() {
    //   Util.toggleFullScreen();
    // },
    logout() {
      this.$store.dispatch('UserStore/signOut').then(() => {
        this.$router.replace({ path: '/book-appointment' });
      });
    },
    clearDlg() {
      this.dlgShow = false;
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
    },
    showChangePasswordDlg() {
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.dlgModel = {
        Email: this.$store.getters['UserStore/user'].Username,
        OldPwd: '',
        NewPwd: '',
        ConPwd: ''
      };
      this.dlgShow = true;
    },
    async saveDlg() {
      const res = await this.$validator.validateAll();
      if (!res) return;
      //this.dlgModel.Email = this.$store.getters['UserStore/user'].UserName;
      this.api_post(this.dlgModel);
    },
    async api_post(data) {
      //console.log(data.Email);
      this.submit.working = true;
      const res = await WebH.Post('/user', data);
      this.submit.working = false;
      if (res.Success) {
        this.$notify({ type: 'info', text: 'Successfully changed password' });
        this.clearDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped>
img.resize {
  max-width: 50%;
  max-height: 50%;
}
</style>
