import drugsApi from '../../../api/drugs';
import drugCatsApi from '../../../api/drugCatsApi';

const state = {
  drugs: [],
  cats: []
};

const mutations = {
  setDrugs(state, data) {
    state.drugs = data;
  },
  addDrug(state, data) {
    state.drugs.push(data);
  },
  updateDrug(state, data) {
    const drug = state.drugs.find(x => x.Id === data.Id);
    if (drug) {
      drug.Name = data.Name;
      drug.Composition = data.Composition;
      drug.Dose = data.Dose;
      drug.Dosage = data.Dosage;
    }
  },
  deleteDrug(state, data) {
    const index = state.drugs.indexOf(data);
    if (index >= 0) {
      state.drugs.splice(index, 1);
    }
  },
  setCats(state, data) {
    state.cats = data;
  },
  addCat(state, data) {
    state.cats.push(data);
  },
  updateCat(state, data) {
    const cat = state.cats.find(x => x.Id === data.Id);
    if (cat) {
      cat.Name = data.Name;
    }
  },
  deleteCat(state, data) {
    //console.log(data);
    const index = state.cats.indexOf(data);
    //console.log(index);
    if (index > -1) {
      state.cats.splice(index, 1);
    }
  }
};

const actions = {
  async loadDrugs({ dispatch }) {
    await dispatch('api_getCats');
    await dispatch('api_getDrugs');
  },
  async api_getCats({ commit }) {
    const res = await drugCatsApi.get();
    if (res) {
      await commit('setCats', res);
    }
  },
  async api_getDrugs({ commit }) {
    const res = await drugsApi.get();
    if (res) {
      await commit('setDrugs', res);
    }
  }
};

const getters = {
  Drugs: state => {
    return state.drugs;
  },
  // Drug: state => drugId => {
  //   console.log(drugId);
  //   const dr = state.drugs.find(x => x.Id === drugId);
  //   console.log(dr);
  //   return dr;
  // },
  Cats: state => {
    return state.cats;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
