const ageConverter = {
  DobToAge(dob) {
    const bDate = new Date(dob);
    const diff_ms = Date.now() - bDate.getTime();
    const age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }
};
export default ageConverter;
