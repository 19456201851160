import Axios from 'axios';
// import Vue from 'vue'

const state = {
  token: '',
  user: {
    Name: '',
    Username: '',
    Address: '',
    City: '',
    PostalCode: '',
    MobileNumber: '',
    Role: ''
  },
  permissions: []
};

const mutations = {
  setUser(state, data) {
    state.token = data.Token;
    state.user.Name = data.Name;
    state.user.Username = data.Username;
    state.user.Address = data.Address;
    state.user.City = data.City;
    state.user.PostalCode = data.PostalCode;
    state.user.MobileNumber = data.MobileNumber;
    state.user.Role = data.Role;
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token;
    const jwt = JSON.parse(atob(state.token.split('.')[1]));
    state.permissions = jwt['bacben_permit'];
  },
  setSession(state) {
    sessionStorage.setItem('token', state.token);
    sessionStorage.setItem('user', JSON.stringify(state.user));
  },
  // setToken (state, token) {
  //   state.token = token
  //   Axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token
  //   sessionStorage.setItem('token', state.token)
  // },
  // setUser (state, username) {
  //   state.userName = username
  //   sessionStorage.setItem('token', state.userName)
  // },
  // setRoles (state, roles) {
  //   state.roles = roles
  //   sessionStorage.setItem('token', state.roles)
  // },
  clearFields(state) {
    state.token = '';
    state.user = {
      Name: '',
      Username: '',
      Address: '',
      City: '',
      PostalCode: '',
      MobileNumber: '',
      Role: ''
    };
    state.permissions = [];
    sessionStorage.clear();
  }
};

const actions = {
  signIn({ commit }, data) {
    commit('setUser', data);
    commit('setSession');
  },
  autoSignIn({ commit }) {
    try {
      var data = {
        Token: sessionStorage.getItem('token'),
        User: JSON.parse(sessionStorage.getItem('user'))
      };
      if (data.Token != null && data.User != null) {
        commit('setUser', data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  signOut({ commit }) {
    commit('clearFields');
    this.snackbar = {
      show: true,
      color: 'green',
      text: 'Logout successfully.'
    };
  }
};

const getters = {
  token: state => {
    return state.token;
  },
  user: state => {
    return state.user;
  },
  permissions: state => {
    return state.permissions;
  },
  fullName: state => {
    return state.user.Name;
  }
  // jwtData: state =>
  //   state.token ? JSON.parse(atob(state.token.split('.')[1])) : null
  //   isAdmin: state => {
  //     return !!state.user.roles.includes('Admin')
  //   }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
