import IntToSlotConverter from "./ApptSlotFilter";
import dateFormats from "./DateFilter";
import genderConverter from "./genderFilter";
import complaintsConverter from "./SmellFilter";
import ageConverter from "./AgeFilter";
import IntToApptStatusConverter from "./ApptStatusFilter";

export default {
  install(Vue) {
    Vue.filter("genderIntString", genderConverter.IntToString);
    Vue.filter("genderStrInt", genderConverter.StringToInt);
    Vue.filter("IntToSlot", IntToSlotConverter);
    Vue.filter("IntToApptStatus", IntToApptStatusConverter);
    Vue.filter("FormatDateTime", dateFormats.FormatDateTime);
    Vue.filter("FormatDateIST", dateFormats.FormatDateIST);
    Vue.filter("FormatDateISO", dateFormats.FormatDateISO);
    Vue.filter("FormatDateTimeISO", dateFormats.FormatDateTimeISO);
    Vue.filter("ToSmell", complaintsConverter.ToSmell);
    Vue.filter("ToSymptomsTime", complaintsConverter.ToSymptomsTime);
    Vue.filter("DobToAge", ageConverter.DobToAge);
  }
};
