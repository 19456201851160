const ComplaintsConverter = {
  /**
   * @return {string}
   */
  ToSmell(value) {
    let smell = "";
    switch (value) {
      case "aa":
        smell = "Good";
        break;
      case "a":
        smell = "Above average";
        break;
      case "b":
        smell = "Below average";
        break;
      case "n":
        smell = "Not smelling";
        break;
    }
    return smell;
  },

  /**
   * @return {string}
   */
  ToSymptomsTime(value) {
    if (value === "Both") return "Day and Night";
    return value;
  }
};
export default ComplaintsConverter;
