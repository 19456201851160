const Menu = [
  {
    title: "Dashboard",
    name: "Dashboard",
    icon: "fa fa-tachometer-alt"
  },
  {
    title: "Appointments",
    name: "appointments",
    icon: "fa fa-calendar-check"
  },
  {
    title: "Patient List",
    name: "patients",
    icon: "fa fa-procedures",
    permission: "pat.v"
  },
  {
    title: "Patient Questionnaire",
    name: "patient_questionnaire",
    icon: "fa fa-check-square",
    permission: "pat.v"
  },
  {
    title: "Forms",
    group: "forms",
    icon: "fa fa-file-medical",
    permission: "pat.v",
    items: [
      {
        title: "Allergy Survey Sheet",
        alias: "forms/allergy_survey_sheet",
        permission: "pat.v"
      }
    ]
  },
  {
    title: "Examination",
    name: "exams",
    icon: "fa fa-file-medical-alt",
    permission: "pat.v"
  },
  {
    title: "Investigation",
    name: "investigations",
    icon: "fa fa-notes-medical",
    permission: "pat.v"
  },
  {
    title: "Diagnoses",
    name: "diagnoses",
    icon: "fa fa-diagnoses",
    permission: "pat.v"
  },
  {
    title: "Skin Prick Tests",
    name: "spt",
    icon: "fa fa-allergies",
    permission: "s.v"
  },
  {
    title: "Specific IgE Tests",
    name: "ige",
    icon: "fa fa-vials",
    permission: "l.v"
  },
  {
    title: "Prescription",
    name: "prescription",
    icon: "fa fa-prescription-bottle",
    permission: "dr.v"
  },
  {
    title: "Case-sheet",
    name: "case_sheet",
    icon: "fa fa-book-medical"
  },
  {
    title: "SMS",
    icon: "fa fa-sms",
    name: "sms"
  },
  {
    title: "Medicines",
    group: "med",
    icon: "fa fa-pills",
    permission: "dr.v",
    items: [
      {
        title: "Categories",
        alias: "med/cats",
        permission: "dr.v"
      },
      {
        title: "Drugs",
        alias: "med/drugs",
        permission: "dr.v"
      },
      {
        title: "Drug Prescriptions",
        alias: "med/drugPrescriptions",
        permission: "dr.v"
      }
    ]
  },
  // {
  //   title: "Medicines",
  //   name: "drugs",
  //   icon: "fa fa-pills",
  //   permission: "dr.v"
  // },
  // {
  //   title: "Settings",
  //   name: "settings",
  //   icon: "fa fa-cog",
  //   permission: "set.v"
  // },
  // {
  //   title: "Question Settings",
  //   group: "questions",
  //   icon: "fa fa-question",
  //   permission: "pat.v",
  //   items: [
  //     {
  //       title: "Questions",
  //       alias: "questions/quest"
  //     },
  //     {
  //       title: "Question Groups",
  //       alias: "questions/groups"
  //     }
  //   ]
  // },
  {
    title: "Accounts",
    group: "accounts",
    icon: "fa fa-user-alt",
    permission: "usr.v",
    items: [
      {
        title: "Users",
        alias: "accounts/users",
        permission: "usr.v"
      },
      {
        title: "Roles",
        alias: "accounts/roles",
        permission: "usr.v"
      }
    ]
  }
  // {
  //   title: "Old Data",
  //   name: "old-data",
  //   icon: "fa fa-database",
  //   permission: "pat.v"
  // }
];

export default Menu;
