import WebH from '../../../api/web';
// import answerHelpers from '../../../api/appointmentHelpers';

const state = {
  settings: [],
  patientId: ''
  // compQuests: [],
  // feedbackQuests: [],
  // examQuests: [],
  // habitQuests: []
};

const mutations = {
  setSettings(state, data) {
    // state.compQuests = [];
    // state.feedbackQuests = [];
    // state.examQuests = [];
    // state.habitQuests = [];
    state.settings = data;
  },
  updateSettings(state, data) {
    // state.compQuests = [];
    // state.feedbackQuests = [];
    // state.examQuests = [];
    // state.habitQuests = [];
    const setting = state.settings.find(x => x.Id === data.Id);
    if (setting) setting.JsonValue = data.JsonValue;
  },
  setPatientId(state,data){
    state.patientId=data;
  }
  // setCompQuests(state, data) {
  //   state.compQuests = data;
  // },
  // setFeedbackQuests(state, data) {
  //   state.feedbackQuests = data;
  // },
  // setExamQuests(state, data) {
  //   state.examQuests = data;
  // },
  // setHabitQuests(state, data) {
  //   state.habitQuests = data;
  // }
};

const actions = {
  //setSettings({ dispatch, commit }, data) {
  setSettings({ commit }, data) {
    commit('setSettings', data);
    // dispatch('api_GetComplaintQuestions');
    // dispatch('api_GetFeedbackQuestions');
    // dispatch('api_GetExamQuestions');
  },
  updateSettings({ commit }, data) {
    commit('updateSettings', data);
  },
  // setCompQuests({ commit }, data) {
  //   commit('setCompQuests', data);
  // },
  // setFeedbackQuests({ commit }, data) {
  //   commit('setFeedbackQuests', data);
  // },
  // setExamQuests({ commit }, data) {
  //   commit('setExamQuests', data);
  // },
  // setHabitQuests({ commit }, data) {
  //   commit('setHabitQuests', data);
  // },
  async loadSettings({ dispatch }) {
    await dispatch('api_GetSettings');
    // await dispatch('api_GetComplaintQuestions');
    // await dispatch('api_GetFeedbackQuestions');
    // await dispatch('api_GetExamQuestions');
    // await dispatch('api_GetHabitQuestions');
  },
  async api_GetSettings({ dispatch }) {
    const res = await WebH.Get('/settings', 'settings');
    if (res.Success) {
      await dispatch('setSettings', res.Data);
    }
  },
  // async api_GetComplaintQuestions({ dispatch, state }) {
  //   try {
  //     const setting = state.settings.find(x => x.Name === 'complaint_set');
  //     if (!setting) {
  //       this.$notify({
  //         type: 'error',
  //         text: 'complaint_set setting is missing'
  //       });
  //       return;
  //     }
  //     const qGroup = JSON.parse(setting.JsonValue).Val;
  //     const resp = await Axios.get('/questions/byGroupId?groupId=' + qGroup);
  //     const questions = answerHelpers.initQuestions(resp.data);
  //     await dispatch('setCompQuests', questions);
  //   } catch (error) {
  //     notifyMessage.errorMessage(error, 'when loading complaint_set');
  //   }
  // },
  // async api_GetFeedbackQuestions({ dispatch, state }) {
  //   try {
  //     const setting = state.settings.find(x => x.Name === 'feedback_set');
  //     if (!setting) {
  //       this.$notify({
  //         type: 'error',
  //         text: 'feedback_set setting is missing'
  //       });
  //       return;
  //     }
  //     const qGroup = JSON.parse(setting.JsonValue).Val;
  //     const resp = await Axios.get('/questions/byGroupId?groupId=' + qGroup);
  //     const questions = answerHelpers.initQuestions(resp.data);
  //     await dispatch('setFeedbackQuests', questions);
  //   } catch (error) {
  //     notifyMessage.errorMessage(error, 'when loading feedback questions');
  //   }
  // },
  // async api_GetExamQuestions({ dispatch, state }) {
  //   try {
  //     const setting = state.settings.find(x => x.Name === 'exam_set');
  //     if (!setting) {
  //       this.$notify({
  //         type: 'error',
  //         text: 'exam_set setting is missing'
  //       });
  //       return;
  //     }
  //     const qGroup = JSON.parse(setting.JsonValue).Val;
  //     const resp = await Axios.get('/questions/byGroupId?groupId=' + qGroup);
  //     const questions = answerHelpers.initQuestions(resp.data);
  //     await dispatch('setExamQuests', questions);
  //   } catch (error) {
  //     notifyMessage.errorMessage(error, 'when loading examinations questions');
  //   }
  // },
  // async api_GetHabitQuestions({ dispatch, state }) {
  //   try {
  //     const setting = state.settings.find(x => x.Name === 'habit_set');
  //     if (!setting) {
  //       this.$notify({
  //         type: 'error',
  //         text: 'habit_set setting is missing'
  //       });
  //       return;
  //     }
  //     const qGroup = JSON.parse(setting.JsonValue).Val;
  //     const resp = await Axios.get('/questions/byGroupId?groupId=' + qGroup);
  //     const questions = answerHelpers.initQuestions(resp.data);
  //     await dispatch('setHabitQuests', questions);
  //   } catch (error) {
  //     notifyMessage.errorMessage(error, 'when loading habit questions');
  //   }
  // }
  setPatientId({commit},data){
    commit('setPatientId', data);
  }
};

const getters = {
  settings: state => {
    return state.settings;
  },
  patientId: state=>{
    return state.patientId;
  }
  // setting:state=>name=>{
  //     return state.settings.find(x=>x.Name===name);
  // },
  // compQuests:state=>{
  //     return state.compQuests;
  // },
  // feedbackQuests:state=>{
  //     return state.feedbackQuests;
  // },
  // examQuests:state=>{
  //     return state.examQuests;
  // },
  // habitQuests:state=>{
  //     return state.habitQuests;
  // }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
